import _interceptor from "../interceptor";
import _mixin from "../util/mixin";
var exports = {};
var interceptor, mixinUtil, defaulter;
interceptor = _interceptor;
mixinUtil = _mixin;

defaulter = function () {
  function mixin(prop, target, defaults) {
    if (prop in target || prop in defaults) {
      target[prop] = mixinUtil({}, defaults[prop], target[prop]);
    }
  }

  function copy(prop, target, defaults) {
    if (prop in defaults && !(prop in target)) {
      target[prop] = defaults[prop];
    }
  }

  var mappings = {
    method: copy,
    path: copy,
    params: mixin,
    headers: mixin,
    entity: copy,
    mixin: mixin
  };
  return function (target, defaults) {
    for (var prop in mappings) {
      /*jshint forin: false */
      mappings[prop](prop, target, defaults);
    }

    return target;
  };
}();
/**
 * Provide default values for a request. These values will be applied to the
 * request if the request object does not already contain an explicit value.
 *
 * For 'params', 'headers', and 'mixin', individual values are mixed in with the
 * request's values. The result is a new object representiing the combined
 * request and config values. Neither input object is mutated.
 *
 * @param {Client} [client] client to wrap
 * @param {string} [config.method] the default method
 * @param {string} [config.path] the default path
 * @param {Object} [config.params] the default params, mixed with the request's existing params
 * @param {Object} [config.headers] the default headers, mixed with the request's existing headers
 * @param {Object} [config.mixin] the default "mixins" (http/https options), mixed with the request's existing "mixins"
 *
 * @returns {Client}
 */


exports = interceptor({
  request: function handleRequest(request, config) {
    return defaulter(request, config);
  }
});
export default exports;